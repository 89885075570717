import VenmoIcon from '../assets/images/venmo.png';
import TicketMasterIcon from '../assets/images/ticketmaster.png';
import RevolutIcon from '../assets/images/revolut.png';
import GarantiIcon from '../assets/images/garanti.png';
import NamecheapIcon from '../assets/images/namecheap.png';
import GodaddyIcon from '../assets/images/godaddy.png';
import EventbriteIcon from '../assets/images/eventbrite.png';
import SquarespaceIcon from '../assets/images/squarespace.png';
import SeatGeekIcon from '../assets/images/seatgeek.png';
import RampBackground from '../assets/images/ramp_background.png';
import TicketBackground from '../assets/images/tickets_background.png';
import DomainBackground from '../assets/images/domains_background.png';
import { colors } from '@theme/colors';

const isTicketsEnabled = process.env.ENABLE_TICKETS === 'true';

export const MARKETS_CARDS = [
	{
		title: 'Ramp',
		description: [
			'Onramp USD, EUR, GBP, SGD and more in 90 seconds using everyday payment apps',
			'Pay a fraction of the fees of centralized onramps using ZKP2P\'s secure P2P model',
			'Provide USDC liquidity as an offramper to earn fees passively',
		],
		cta: 'Onramp Your Fiat Now',
		navigateTo: 'https://ramp.zkp2p.xyz/',
		subtitle: 'Supports Venmo, Revolut, Garanti',
		disabled: false,
	},
	{
		title: 'Domains',
		description: [
			'Buy domains for up to 90% less fees than other domain marketplaces',
			'Keep your domains on your registrar of choice as a seller--no need to transfer to list',
			'Enjoy a simple settlement process that pays out upon domain transfer acceptance',
		],
		cta: 'Find the Perfect Domain Now',
		navigateTo: 'https://domains.zkp2p.xyz/',
		subtitle: 'Supports Namecheap',
		disabled: false,
	},
	{
		title: 'Tickets',
		description: [
			'Buy and sell tickets for a up to 80% less fees',
			'All tickets are verified to be authentic and are transferable prior to listing',
			'Sellers receive sale proceeds instantly upon ticket acceptance',
		],
		cta: 'Buy or Sell Tickets Now',
		navigateTo: 'https://tickets.zkp2p.xyz/',
		subtitle: 'Supports Ticketmaster',
		disabled: !isTicketsEnabled,
	},
];

export const CONNECT_CARDS = [
	{
		title: 'Solutions',
		description: 'Let us help you automate your business - contact us to see how we can help with your verification needs',
		cta: '',
		navigateTo: 'mailto:team@zkp2p.xyz',
	},
	{
		title: 'Documentation',
		description: 'Learn how to use ZKP2P',
		cta: '',
		navigateTo: 'https://docs.zkp2p.xyz/',
	},
	{
		title: 'Community',
		description: 'Follow us on X for the latest updates',
		cta: '',
		navigateTo: 'https://x.com/ZKP2P',
	},
];

export const CAROUSEL_ITEMS = [
	{
		title: "Fiat Onramp",
		buttonText: "Go to Ramp",
		description: "Use Venmo, Revolut, and more to onramp your fiat in 90 seconds",
		buttonLink: "https://ramp.zkp2p.xyz",
		backgroundImage: `url(${RampBackground})`,
		icons: [VenmoIcon, RevolutIcon, GarantiIcon],
		buttonColor: colors.buttonRed,
		buttonTextColor: colors.white,
		buttonHoverColor: colors.buttonRedHover,
		disabled: false,
	},
	{
		title: "Domains",
		buttonText: "Go to Domains",
		description: "Trade Namecheap registered domains for up to 90% less fees",
		buttonLink: "https://domains.zkp2p.xyz",
		backgroundImage: `url(${DomainBackground})`,
		icons: [NamecheapIcon, GodaddyIcon, SquarespaceIcon],
		buttonColor: colors.buttonOrange,
		buttonTextColor: colors.white,
		buttonHoverColor: colors.buttonOrangeHover,
		disabled: false,
	},
	{
		title: "Tickets",
		buttonText: "Go to Tickets",
		description: "Buy pre-authenticated Ticketmaster tickets for up to 80% less fees.",
		buttonLink: "https://tickets.zkp2p.xyz",
		backgroundImage: `url(${TicketBackground})`,
		icons: [TicketMasterIcon, EventbriteIcon, SeatGeekIcon],
		buttonColor: colors.buttonBlue,
		buttonTextColor: colors.white,
		buttonHoverColor: colors.buttonBlueHover,
		disabled: !isTicketsEnabled,
	},
];

export const VALUE_CARDS = [
	{
		title: "Fast",
		description: "Buyers receive their items and sellers receive their payouts at the time of sale",
	},
	{
		title: "Cheap",
		description: "Save up to 90% on transaction fees compared to other marketplaces. Buyers pay less, sellers make more.",
	},
	{
		title: "Zero fraud",
		description: "Have full confidence in the authenticity and delivery of the goods you buy",
	},
];

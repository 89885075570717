import styled from 'styled-components';
import { ArrowRight } from 'react-feather';

import { colors } from '@theme/colors';
import useQuery from '@hooks/useQuery';

const StyledArrowRight = styled(ArrowRight)`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color: ${colors.darkText};
`

const StyledCard = styled.div<{ cursor: string, backgroundColor: string, disabled: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
  background-origin: border-box;
  text-decoration: none;
  color: ${({ theme }) => theme.neutral1};
  height: auto;
  min-height: 228px;
  border-radius: 24px;
  border: 1px solid ${colors.defaultBorderColor};
  cursor: ${({ cursor, disabled }) => disabled ? 'default' : cursor};
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
  padding: 2rem;

  &:hover {
    transform: ${({ disabled }) => disabled ? 'none' : 'translateY(-4px)'};
    opacity: ${({ disabled }) => disabled ? 0.6 : 1};

  &:hover ${StyledArrowRight} {
    opacity: ${({ disabled }) => disabled ? 0.6 : 1};
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.container};
  padding: 1rem;
  position: relative;
  gap: 16px;
`

const CardTitle = styled.div`
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: ${colors.darkText};
  text-align: center;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const CardDescription = styled.ul`
  font-size: 16px;
  line-height: 28px;
  color: ${colors.darkText};
  margin-bottom: 16px;
  text-align: left;
  padding-left: 20px;
`

const DescriptionItem = styled.li`
  margin-bottom: 8px;
`

const DescriptionContainer = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
`

const CardCTA = styled.div<{ disabled: boolean }>`
  color: ${colors.cardCTAText};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-top: auto;
  transition: opacity 250ms ease 0s;
  opacity: ${({ disabled }) => disabled ? 0.6 : 1};
  &:hover {
    opacity: ${({ disabled }) => disabled ? 0.6 : 1};
  }
`

const CardSubtitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: ${colors.darkText};
  margin-bottom: 16px;
  text-align: center;
`

const MarketCard = ({
  title,
  description,
  subtitle,
  icon,
  cta,
  navigateTo,
  backgroundColor = colors.container,
  disabled = false,
}: {
  title: string
  description: string[]  // Change this to an array of strings
  subtitle: string
  icon?: React.ReactNode
  cta?: string
  navigateTo?: string
  backgroundColor?: string
  disabled?: boolean
}) => {
  const { navigateWithQuery } = useQuery()

  const handleClick = () => {
    if (navigateTo) {
      if (navigateTo.startsWith('http')) {
        window.open(navigateTo, '_blank')
      } else {
        navigateWithQuery(navigateTo)
      }
    }
  }

  return (
    <StyledCard
      cursor={navigateTo ? 'pointer' : 'default'}
      onClick={disabled ? undefined : handleClick}
      backgroundColor={backgroundColor}
      disabled={disabled}
    >
      <TitleContainer>
        <CardTitle>{title}</CardTitle>
        {icon && <IconWrapper>{icon}</IconWrapper>}
      </TitleContainer>

      <ContentContainer>
        <CardSubtitle>{subtitle}</CardSubtitle>
        <DescriptionContainer>
          <CardDescription>
            {description.map((item, index) => (
              <DescriptionItem key={index}>{item}</DescriptionItem>
            ))}
          </CardDescription>
        </DescriptionContainer>
        {cta && (
          <CardCTA disabled={disabled}>
            {disabled ? 'Coming Soon' : cta}
            {!disabled && <StyledArrowRight size={16} style={{ marginLeft: '8px', verticalAlign: 'middle' }} />}
          </CardCTA>
        )}
      </ContentContainer>
    </StyledCard>
  )
}

export default MarketCard

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import useMediaQuery from '@hooks/useMediaQuery';
import { useScrollPosition } from '@hooks/useScrollPosition';

import { BottomNav } from "@components/layouts/BottomNav";
import { Landing } from "./pages/Landing";
import { Privacy } from "./pages/Privacy";
import { Tos } from "./pages/Tos";

import "./App.css";
import "./styles.css";

const App = () => {
  /*
   * Context
   */

  const currentDeviceSize = useMediaQuery();
  const scrollPosition = useScrollPosition();

  const isMobileOrTablet = currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile';
  const showBottomNav = isMobileOrTablet && scrollPosition > 100;

  /*
   * Component
   */

  return (
    <Router>
      <div className="app-container">
        <div className="app-content">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/pp" element={<Privacy />} />
            <Route path="/tos" element={<Tos />} />
            <Route element={<>Not found</>} />
          </Routes>
        </div>
  
        {showBottomNav && <BottomNav />}
      </div>
    </Router>
  );
};

export default App;
